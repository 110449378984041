:root {
  --text-color: #f2ead6;
  --link-color: #66b5ff;
  --main-bg-color: #000;
  --green-color: #3a9200;
  --red-color: #d20003;
  --yellow-color: #ffd600;
}

body {
  background: var(--main-bg-color);
  color: var(--text-color);
  font-size: 16px;
  font-family: "Libre Baskerville", serif;
  line-height: 1.4;
  margin: 0;
}

html {
  -webkit-text-size-adjust: 100%;
}

/*------------------------ Font Styles ------------------------*/

strong {
  font-weight: bold;
}

.italic {
  font-style: italic;
  font-weight: bold;
  letter-spacing: 2px;
}

/*------------------------ Colors ------------------------*/

.green {
  color: var(--green-color);
}

.red {
  color: var(--red-color);
}

/*------------------------ Wrappers ------------------------*/

.parallax-banner {
  height: 100vh;
}
.parallax-banner > div:first-child {
  inset: 0 !important;
}

.container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.container.dark::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.container.xtra-dark::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.container.light::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

/*------------------------ Content and Media ------------------------*/

.content {
  max-width: 780px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  padding: 1em;
}
.content a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: bold;
}

.media {
  padding: 0;
  margin: 1em auto 2.5em auto;
  display: block;
  text-align: center;
}
.media img {
  max-width: 50%;
}
.media.fullwidth img {
  max-width: 100%;
}

/*------------------------ Screen Orientation ------------------------*/

@media (orientation: landscape) {
  .content-orientation-landscape {
    display: block;
  }
  .content-orientation-portrait {
    display: none;
  }
}

@media (orientation: portrait) {
  .content-orientation-portrait {
    display: block;
  }
  .content-orientation-landscape {
    display: none;
  }
}

.screen-orientation-msg {
  padding: 2rem;
  text-align: center;
}
.screen-orientation-msg img {
  width: 50%;
}
.screen-orientation-msg p {
  font-family: "Cutive Mono", monospace;
}
.screen-orientation-msg p.large {
  font-size: 1.2em;
}
.screen-orientation-msg footer {
  border-top: 1px solid;
  padding-top: 1em;
}

/*------------------------ Social Media Share and Contact ------------------------*/

.contact,
.social-share {
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-btn {
  margin-right: 0.4em;
}

#slide-1 .social-share {
  position: absolute;
  top: 1em;
  right: 1em;
}

.contact h2,
.contact-email a,
.social-share h2 {
  font-family: "Cutive Mono", monospace;
  font-size: 1em;
  margin-right: 0.6em;
}

.contact-email a {
  color: var(--link-color);
}

.contact h2,
.social-share h2,
.contact-email,
.social-share .share-icons {
  display: inline;
}

/*------------------------ Copyright ------------------------*/

.copyright {
  text-align: center;
  margin-top: 3em;
}

/*------------------------ Slides ------------------------*/

/* Intro */
header {
  max-width: 820px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  bottom: 5%;
  text-align: center;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 690px) {
  header {
    top: auto;
    bottom: 8%;
  }
}
header h1 {
  margin: 0 auto 0.6em auto;
  color: var(--yellow-color);
  font-size: 1.1em;
  line-height: 1.3;
  max-width: 100%;
}
@media screen and (min-width: 500px) {
  header h1 {
    font-size: 1.3em;
  }
}
@media screen and (min-width: 690px) {
  header h1 {
    font-size: 1.6em;
    max-width: 95%;
  }
}
@media screen and (min-width: 770px) {
  header h1 {
    font-size: 2.1em;
  }
}
@media screen and (min-width: 870px) {
  header h1 {
    font-size: 2.4em;
    max-width: 80%;
  }
}
@media screen and (min-width: 1000px) {
  header h1 {
    max-width: 100%;
    font-size: 2.7em;
  }
}
@media screen and (min-width: 1200px) {
  header h1 {
    font-size: 3.2em;
  }
}
.slide-intro__info {
  background: rgba(255, 255, 255, 0.75);
  margin: 0 auto 0.35em auto;
  padding: 0.3em 1em;
}
.slide-intro__info p {
  margin: 0;
  font-family: "Cutive Mono", monospace;
  color: #000;
  text-align: left;
  font-size: 0.9em;
  line-height: 1.4;
}
@media screen and (min-width: 690px) {
  .slide-intro__info {
    font-size: 1em;
    margin-bottom: 5%;
    padding: 1em;
  }
}
@media screen and (min-width: 1000px) {
  .slide-intro__info {
    margin-bottom: 8%;
  }
}
header a {
  text-decoration: underline;
}
header .scroll-icon {
  font-family: "Cutive Mono", monospace;
  display: block;
}
header .scroll-icon span {
  display: block;
  margin-bottom: 0.2em;
  font-weight: bold;
  font-size: 0.8em;
}
@media screen and (min-width: 690px) {
  header .scroll-icon span {
    display: block;
    margin-top: 1em;
    font-size: 1em;
  }
}

/* Slide Black */
.slide-black.container {
  background-color: var(--main-bg-color);
}
.slide-black .content {
  height: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slide-black .content p {
  text-align: left;
  font-weight: normal;
  line-height: 1.45;
  font-size: 1em;
  margin: auto;
  max-width: 500px;
}
.slide-black .content p:first-letter {
  display: block;
  float: left;
  font-size: 310%;
  font-weight: bold;
  line-height: 60%;
  margin-right: 8px;
  margin-top: 9px;
}
.slide-black .content p + p {
  margin-top: 0;
}
@media screen and (min-width: 768px) {
  .slide-black .content p {
    font-size: 1.4em;
    max-width: 800px;
  }
  .slide-black .content p:first-letter {
    margin-top: 12px;
  }
}
@media screen and (min-width: 1200px) {
  .slide-black .content p {
    font-size: 1.9em;
    line-height: 1.65;
  }
  .slide-black .content p:first-letter {
    font-size: 345%;
    margin-top: 19px;
  }
}

/* Photo slides */
.slide-photo .content {
  margin: 2%;
  position: absolute;
  bottom: 0;
  text-align: left;
  padding: 0.8em;
  background: rgba(0, 0, 0, 0.6);
}
.slide-photo .content p {
  font-family: "Cutive Mono", monospace;
  text-align: left;
  font-size: 0.9em;
  line-height: 1.4;
  margin: 0 auto;
}
.slide-photo .content p:not(:last-child) {
  padding-bottom: 0.5em;
}
@media screen and (min-width: 680px) {
  .slide-photo .content {
    margin: 6%;
  }
  .slide-photo .content p {
    font-size: 1em;
  }
}
@media screen and (min-width: 768px) {
  .slide-photo .content {
    bottom: auto;
  }
  .slide-photo .content p {
    font-size: 1.1em;
    max-width: 450px;
  }
}

/* Slide 3: Bus */
@media screen and (min-width: 1000px) {
  #slide-3 .content {
    margin-top: 15%;
    max-width: 550px;
  }
}

/* Slide 5: Ripped Card */
#slide-5 .content {
  max-width: 55%;
  text-align: right;
  margin-top: 0;
  margin-right: 0;
  right: 0;
  bottom: 0;
}
@media screen and (min-width: 1000px) {
  #slide-5 .content {
    margin-right: 3%;
    margin-top: 5%;
    max-width: 510px;
  }
}

/* Slide 7: Second Attempt */
@media screen and (min-width: 1000px) {
  #slide-7 .content {
    margin-left: 6%;
    max-width: 570px;
    bottom: 5%;
  }
}

/* Slide 8: Vung Tau */
@media screen and (min-width: 1000px) {
  #slide-8 .content {
    margin-right: 6%;
    max-width: 570px;
    bottom: 5%;
    right: 0;
  }
}

/* Slide 10: Empty house */
@media screen and (min-width: 1000px) {
  #slide-10 .content {
    margin-right: 6%;
    max-width: 570px;
    bottom: 5%;
    right: 0;
  }
}

/* Slide 12/13: Prison */
@media screen and (min-width: 1000px) {
  #slide-12 .content {
    margin-right: 3%;
    margin-top: 12%;
    max-width: 570px;
  }
}
#slide-13 .content {
  margin-right: 3%;
  margin-top: 12%;
  max-width: 570px;
  text-align: left;
  background: none;
}
@media screen and (min-width: 1000px) {
  #slide-13 .content {
    margin-right: 3%;
    margin-top: 12%;
    max-width: 570px;
  }
}

/* Slide 14: reunited */
@media screen and (min-width: 1000px) {
  #slide-14 .content {
    margin-top: 19%;
    max-width: 740px;
  }
}

/* Slide 16: bike messenger */
@media screen and (min-width: 1000px) {
  #slide-16 .content {
    max-width: 670px;
  }
}

/* Slide 18: Final words */
@media screen and (min-width: 1000px) {
  #slide-18 .content {
    margin-top: 19%;
    max-width: 860px;
  }
}

/* End slides */
.slide-end {
  width: auto;
  background-color: var(--main-bg-color);
  color: var(--text-color);
  padding: 3em 2em;
}
.slide-end .content {
  padding: 0;
  margin: 0 auto;
  text-align: left;
  max-width: 670px;
}
.slide-end .content figcaption,
.slide-end .content p {
  font-family: "Cutive Mono", monospace;
  text-align: left;
  font-size: 0.9em;
  line-height: 1.4;
  margin: 0 auto;
}
.slide-end .content figcaption {
  font-style: italic;
}
.slide-end .content p:not(:last-child) {
  padding-bottom: 0.5em;
}
@media screen and (min-width: 680px) {
  .slide-end {
    padding: 6em 2em;
  }
  .slide-end .content p {
    font-size: 1em;
  }
}
@media screen and (min-width: 768px) {
  .slide-end .content p {
    font-size: 1.1em;
  }
}
